/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


.backdrop-blur-none {
    backdrop-filter: blur(0);
}

.backdrop-blur-sm {
    backdrop-filter: blur(4px);
}

.backdrop-blur {
    backdrop-filter: blur(8px) brightness(70%);
}

.backdrop-blur-md {
    backdrop-filter: blur(12px);
}

.backdrop-blur-lg {
    backdrop-filter: blur(16px);
}

.backdrop-blur-xl {
    backdrop-filter: blur(24px);
}

.backdrop-blur-2xl {
    backdrop-filter: blur(40px);
}

.backdrop-blur-3xl {
    backdrop-filter: blur(64px);
}

.multiline-ellipsis {
    display: inline-block;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    white-space: pre-wrap;
    text-overflow: ellipsis;
}

.multiline-ellipsis-2 {
    display: inline-block;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    text-overflow: ellipsis;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: none;
    z-index: -3;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: none;
    z-index: -3;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #888;
    z-index: -3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    z-index: -3;
}



input {
    --placeHolder-color: #A8ADAB;
}

input::placeholder {
    color: #A8ADAB;
}

input::-webkit-input-placeholder { /* Edge */
    color: #A8ADAB;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A8ADAB;
}

input:-moz-placeholder {
    color: #A8ADAB;
}

input::-moz-placeholder {
    color: #A8ADAB;
}
//linear-gradient(90deg, #77C3A4 0%, #93A1C4 100%)

.fa-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#77C3A4), to(#93A1C4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

